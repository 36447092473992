<template>
  <div v-if="sourceList.length > 0">
    <div class="source-button" :class="expand ? 'expand' : ''" @click="onClick">
      {{ sourceList.length }} source{{ sourceList.length > 1 ? 's' : '' }}
      <div class="source-expand" :class="expand ? 'expand' : ''">></div>
    </div>
    <div
      class="source-list"
      :class="expand ? '' : 'collapsed'"
      :style="{
        maxHeight: sourceList.length * 1.75 + 'em',
        overflow: 'hidden',
      }"
    >
      <div
        :href="src"
        class="source-row"
        @click="onClickSource(src)"
        v-for="src in sourceList"
        :key="src"
      >
        <img class="sources-icon" src="/icons/attach.svg" />
        <div>{{ getSlug(src) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceList: {
      type: Array,
      required: true,
    },
  },

  emits: ['clickSource'],
  data() {
    return {
      expand: false,
    }
  },

  async mounted() {},
  unmounted() {},
  computed: {},
  methods: {
    onClick() {
      this.expand = !this.expand
    },

    onClickSource(src) {
      this.$emit('clickSource', src)
    },

    getSlug(url) {
      return url.split('/').pop()
      // return new URL(url).pathname.match(/[^/]+/g).pop()
    },
  },
}
</script>

<style scoped>
.source-list {
  opacity: 1;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
}

.collapsed {
  max-height: 0 !important;
  opacity: 0;
}

.source-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  font-size: 0.75rem;
  line-height: 1.5;
  padding: 0.125rem 0.25rem;
  overflow: hidden;
  cursor: pointer;
}

.source-row div {
  flex-grow: 1;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
}

.source-row:hover {
  background-color: rgb(26, 92, 125, 0.2);
  border-radius: 0.25rem;
}

.source-button {
  font-size: 0.75rem;
  cursor: pointer;
  width: fit-content;
  background-color: rgb(26, 92, 125, 0.2);
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
}

.source-button.expand {
  background-color: rgb(26, 92, 125, 0.8);
  color: white;
}

.source-expand {
  margin-left: 0.25rem;
  rotate: 90deg;
  transition: rotate 0.2s ease-out;
}

.source-expand.expand {
  rotate: -90deg;
}

.sources-icon {
  width: 1em;
  height: 1em;
}
</style>
